import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [
    {
      path: "/auth/",
      name: "auth",
      component: () => import("../layouts/auth.vue"),
      redirect: { name: "login" },
      meta: { requiresAuth: false },
      children: [
        {
          path: "login",
          name: "login",
          component: () =>
            import("../views/auth/Login.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("../layouts/default.vue"),
      redirect: { name: "tickets" },
      meta: { requiresAuth: true },
      children: [
        {
          path: "order",
          name: "orders",
          component: () => import("../views/Orders.vue"),
          meta: { requiresAuth: true, title: "سفارشات" },
        },
        {
          path: "ticket",
          children: [
            {
              path: "",
              name: "tickets",
              component: () =>
                import("../views/Tickets.vue"),
              meta: {
                requiresAuth: true,
                title: "پشتیبانی و تیکت",
              },
            },
            {
              path: ":id",
              name: "ticket-details",
              component: () =>
                import("../views/Ticket/[id].vue"),
              meta: {
                requiresAuth: true,
                title: "پشتیبانی و تیکت",
              },
            },
          ],
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/ticket",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isLoggedIn =
    !!localStorage.getItem("user_id_token");

  if (to.meta.requiresAuth && !isLoggedIn) {
    // Redirect unauthenticated users to login page with redirect_url
    const redirectUrl = to.fullPath;
    next(
      `/auth/login?redirect_url=${encodeURIComponent(redirectUrl)}`,
    );
  } else if (isLoggedIn) {
    if (to.path === "/auth/login") {
      // If logged in and no redirect_url, go to /tickets
      const redirectPath =
        to.query.redirect_url || "/ticket";
      next(redirectPath);
    } else {
      next(); // Proceed as normal for logged-in users
    }
  } else {
    next(); // Allow access for routes without auth requirements
  }
});

export default router;
