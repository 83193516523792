import "./assets/main.scss";
import "./assets/fonts.css";

import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import { updateGlobalOptions } from "vue3-toastify";
import * as Sentry from "@sentry/vue";

import "vue3-toastify/dist/index.css";
import "./utils/Validation";

updateGlobalOptions({
  rtl: true,
  autoClose: 2500,
  position: "top-right",
  theme: "colored",
});

const app = createApp(App);

app.use(createPinia());

// const integrations = [
//   new Sentry.BrowserTracing({
//     routingInstrumentation:
//       vueRouterInstrumentation(router),
//   }),
//   new Sentry.Replay(),
// ];

Sentry.init({
  app,
  dsn: "https://b6b1ab9d947ff7576c7b6eab380ffdfe@sentry.hamravesh.com/7546",
  // integrations,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.mount("#app");
