import { configure, defineRule } from "vee-validate";
import {
  required,
  email,
  min,
  max,
} from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import fa from "@vee-validate/i18n/dist/locale/fa.json";

defineRule("required", required);
defineRule("email", email);
defineRule("min", min);
defineRule("max", max);

configure({
  validateOnInput: true,
  bails: true,
  validateOnBlur: true,
  validateOnChange: true,
  validateOnModelUpdate: true,
  generateMessage: localize("fa", {
    messages: {
      ...fa.messages,
      required: "این فیلد الزامی است",
    },
  }),
});
setLocale("fa");
