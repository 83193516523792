<script setup>
import { onMounted } from "vue";
import { toast } from "vue3-toastify";
import axios from "axios";

const checkForUpdates = async () => {
  try {
    const res = await axios.get("/portal/version.json");

    const latestVersion = res.data.APP_VERSION;
    const currentVersion = import.meta.env.VITE_APP_VERSION;

    return latestVersion !== currentVersion
      ? "need-update"
      : "no-update";
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

const updateApp = async () => {
  try {
    const data = await checkForUpdates();
    if (data === "need-update") {
      await toast(
        "اپدیت جدید آماده است. بعد از 10 ثانیه صفحه رفرش میشود...",
        {
          type: "success",
          autoClose: 10000,
        },
      );
      setTimeout(() => {
        refreshApp();
      }, 10000);
    }
  } catch (e) {
    console.error(e);
  }
};

const refreshApp = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
  }

  window.location.reload(true);
};

onMounted(() => {
  setInterval(() => {
    updateApp();
  }, 60000);
});
</script>

<template>
  <router-view />
</template>
